// TODO: REFACTORING
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Styles from './style.module.css';
import { dateToHour } from '@utils/dates';
import TimePicker from '../TimePicker';
import { translate as t } from '@utils/translate';

const LpFormTimePicker = ({ options, onSave }) => {
  const TEXTS = {
    DEFINE_TIME: t('defineTime'),
    RETURNING: t('returning'),
    GOING: t('going'),
    SAVE_BUTTON: t('saveButton'),
  };
  const { type } = options;
  const typeLabel =
    (options && type == 'start' ? TEXTS.GOING : TEXTS.RETURNING) || null;
  const [departureTime, setDepartureTime] = useState(dateToHour(new Date()));
  const [returnTime, setReturnTime] = useState(dateToHour(new Date()));

  function saveTime() {
    onSave({
      departureTime,
      returnTime,
      type: options.type,
    });
  }

  function handleChange(data) {
    if (data) {
      if (type === 'start') {
        setDepartureTime(data.time);
      } else {
        setReturnTime(data.time);
      }
    }
  }

  const picker = (
    <>
      <div className={Styles.blockLayer}></div>
      <div className={Styles.container}>
        <h1 className={Styles.title}>
          {TEXTS.DEFINE_TIME}{' '}
          <span className={Styles.typeLabel}>{typeLabel}</span>
        </h1>
        <hr className={Styles.dividerTop} />
        <TimePicker
          type={type}
          departureTime={departureTime}
          returnTime={returnTime}
          onChange={handleChange}
        />
        <hr className={Styles.dividerBottom} />
        <button
          onClick={() => saveTime()}
          className={Styles.button}
          type="button"
        >
          {TEXTS.SAVE_BUTTON}
        </button>
      </div>
    </>
  );

  return options.isVisible ? picker : null;
};

LpFormTimePicker.propTypes = {
  isVisible: PropTypes.bool,
};

LpFormTimePicker.defaultProps = {
  isVisible: false,
};

export default LpFormTimePicker;
