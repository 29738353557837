/**
 * Get node offset position
 *
 * @param {object} node The node object
 * @param {boolean} raw Boolen to define if the method returns a raw number or a string
 */
function getNodePosition(node, raw = true) {
  let top = 0;
  let left = 0;

  if (node) {
    top = raw ? node.offsetTop : `${node.offsetTop}px`;
    left = raw ? node.offsetLeft : `${node.offsetLeft}px`;
  }

  return [top, left];
}

/**
 * Get elements dimensions
 *
 * @param {object} el DOM Element
 */
function getElementDimensions(el) {
  const { width, height } = el.getBoundingClientRect();

  return [width, height];
}

export { getNodePosition, getElementDimensions };
