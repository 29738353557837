/**
 *
 * @param {*} spot
 * @param {*} type
 *  - only-once: Use iata or oaci
 *  - only-oaci: Use only oaci
 *  - only-iata: Use only iata
 *  - both: retrive oaci and iata
 */
export const solveIataOaci = (spot, type = 'both') => {
  let oaci =
    spot.airport && spot.airport.oaci
      ? spot.airport.oaci
      : spot.helipad && spot.helipad.oaci
      ? spot.helipad.oaci
      : null;
  const acronym =
    spot.airport && spot.airport.acronym ? spot.airport.acronym : null;

  if (type == 'only-iata') return acronym;
  else if (type == 'only-oaci') return oaci;
  else if (type == 'only-once') return acronym || oaci;
  else if (type == 'both') {
    let data = [];
    if (acronym) data.push(acronym);
    if (oaci) data.push(oaci);
    return data.join(', ');
  }
  console.warn('Invalid type to solve ${type}');
  return null;
};
