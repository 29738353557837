function roundFive(time) {
  let [hour, minutes] = time.split(':').map(segment => parseInt(segment, 0));
  let roundedMinutes =
    minutes % 15 === 0 ? minutes : minutes - (minutes % 15) + 15;

  if (roundedMinutes === 60) {
    hour = parseInt(hour, 0) + 1;
    roundedMinutes = 0;

    if (hour === 24) {
      hour = 0;
    }
  }

  roundedMinutes = roundedMinutes < 10 ? '0' + roundedMinutes : roundedMinutes;
  hour = hour < 10 ? '0' + hour : hour;

  return `${hour}:${roundedMinutes}`;
}

function getTimeByHour(items, time) {
  return items.find(item => item.time === roundFive(time));
}

function getTimeByKey(items, key) {
  return items.find(item => item.key === key);
}

export { getTimeByHour, getTimeByKey, roundFive };
