// TODO: REFACTORING
import React, { useState, useEffect, createRef } from 'react';
import Swiper from 'react-id-swiper';
import { getTimeByKey, getTimeByHour } from '@utils/timePicker';

import Styles from './style.module.css';
import 'swiper/css/swiper.min.css';
import './swiper.config.css';

const TimePicker = ({ type, departureTime, returnTime, onChange }) => {
  const swiperRef = createRef();
  let mySwiper;

  const swiperParams = {
    direction: 'vertical',
    slidesPerView: 3,
    slidesPerGroup: 1,
    spaceBetween: 10,
    shouldSwiperUpdate: true,
    mousewheel: true,
    slideToClickedSlide: true,
  };

  const [timeItems, setTimeItems] = useState([]);
  const [time, setTime] = useState();

  useEffect(() => {
    const items = [];
    let counter = 0;
    let label;

    for (let i = 0; i < 24; i++) {
      label = i < 10 ? `0${i}` : i;

      for (let j = 0; j < 60; j++) {
        if (j % 15 === 0) {
          items.push({
            key: counter,
            time: `${label}:${j < 10 ? `0${j}` : j}`,
          });

          counter++;
        }
      }
    }

    setTimeItems(items);
  }, []);

  useEffect(() => {
    let time;
    mySwiper = swiperRef.current.swiper;

    if (timeItems.length) {
      mySwiper.on('slideChange', function() {
        setTime(getTimeByKey(timeItems, mySwiper.activeIndex));
      });

      if (type === 'start') {
        time = getTimeByHour(timeItems, departureTime);
      } else {
        time = getTimeByHour(timeItems, returnTime);
      }

      time && mySwiper.slideTo(time.key);
    }
  }, [timeItems]);

  useEffect(() => {
    onChange(time);
  }, [time]);

  return (
    <div className={`custom-time-picker ${Styles.wrapper}`}>
      <Swiper {...swiperParams} ref={swiperRef}>
        <div className={Styles.item} />
        {timeItems.map(item => (
          <div className={Styles.item} key={item.key}>
            {item.time}
          </div>
        ))}
        <div className={Styles.item} />
      </Swiper>
    </div>
  );
};

export default TimePicker;
