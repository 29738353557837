// TODO: REFACTORING
import React, { useState, useEffect, createRef } from 'react';
import { findDOMNode } from 'react-dom';
import { navigate } from 'gatsby';
import { DateRange } from 'react-date-range';
import * as locales from 'react-date-range/dist/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

import Style from './style.module.css';
import { getNodePosition } from './utils';
import {
  dateToString,
  dateToMonthName,
  dateToDay,
  stringToDate,
  dateToHour,
} from '@utils/dates';
import { solveIataOaci } from '@utils/spot';
import LocationsAutoSuggest from '../LocationsAutoSuggest';
import { apiHost, getLocationsRoute } from '../../utils/constants';
import { translate as t, useCurrentLang } from '@utils/translate';
import LpFormTimePicker from '../LpFormTimePicker';
import useOutsideClick from '@utils/useOutsideClick';
import LpFormTimePickerEditBubble from '../LpFormTimePickerEditBubble';
import useRequest from '@utils/useRequest';
import DropDown from '@components/DropDown/index';

// Utils
import { gtmDispatcher } from '@utils/gtm.helper';
import { analyticsGTAG } from '@utils/ga.helper';
import debounce from '@utils/debounce';

const swapIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15.172 11.869"><g transform="translate(15.172) rotate(90)"><g transform="translate(0)"><path fill="#00897b" d="M3.391,0,0,3.363H2.543V9.272h1.7V3.363H6.782Z"/><path fill="#00897b" d="M4.239,5.909V0h-1.7V5.909H0L3.391,9.272,6.782,5.909Z" transform="translate(5.087 5.9)"/></g></g>svg>`;
const onlyDestinationicon =
  '<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15.172 11.869"><g transform="translate(15.172) rotate(90)"><g transform="translate(3 3)"><path fill="#00897b" d="M3.391,0,0,3.363H2.543V9.272h1.7V3.363H6.782Z"/></g></g> </svg>';
const rightArrowIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="#00897b" d="M24 12l-11-8v6h-13v4h13v6z"/></svg>`;
const submitIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18.995 19.787"><path fill="#00897b" d="M19.69,18.023l-4.683-4.9a7.931,7.931,0,1,0-6.08,2.853,7.828,7.828,0,0,0,4.551-1.446L18.2,19.468a1.032,1.032,0,0,0,1.465.029A1.048,1.048,0,0,0,19.69,18.023ZM8.928,2.084A5.905,5.905,0,1,1,3.056,7.989,5.895,5.895,0,0,1,8.928,2.084Z" transform="translate(-0.984)"/></svg>`;

const NumberInput = ({ name, min, max, setNumber, value }) => {
  return (
    <div className={Style.numberInput}>
      <input name={name} type="hidden" value={value} />
      <div
        onClick={() => value - 1 >= min && setNumber(value - 1)}
        className={[Style.less, 'formLess'].join(' ')}
      >
        -
      </div>
      <div className={Style.number}>{value}</div>
      <div
        onClick={() => value + 1 <= max && setNumber(value + 1)}
        className={[Style.more, 'formMore'].join(' ')}
      >
        +
      </div>
    </div>
  );
};

const localeLangsMap = {
  pt_BR: locales.pt,
  en_US: locales.enUS,
  es_ES: locales.es,
  // it_IT: locales.it,
};

const datePickerSettings = {
  direction: 'horizontal',
  dragSelectionEnabled: false,
  editableDateInputs: true,
  minDate: new Date(),
  monthDisplayFormat: 'LLLL',
  moveRangeOnFirstSelection: false,
  showMonthAndYearPickers: false,
  showSelectionPreview: true,
  showDateDisplay: false,
  rangeColors: ['#00BFA5'],
  weekStartsOn: 0,
};

const LpForm = ({
  /* initialCityId,
  initialOriginId,
  initialSuggestion, */
  initialDay,
  backgroundImage,
  onlyDestination,

  initialOriginId,
  initialOriginSuggestion,
  initialDestinationId,
  initialDestinationSuggestion,
}) => {
  const TEXTS = {
    CHARTER_LABEL: t('charter'),
    SHARED_LABEL: t('shared'),
    ALL_AIRPORTS: t('allAirports'),
    ORIGIN: t('origin'),
    DESTINATION: t('destination'),
    PLACES: t('seats'),
    ADULTS_ONLY: t('adults'),
    CHILDRENS: t('children'),
  };
  const FLIGHT_TYPES = {
    CHARTERED: 'charter',
    SHARED: 'flights',
  };
  const flightTypesOptions = [
    { key: 0, label: TEXTS.CHARTER_LABEL, value: FLIGHT_TYPES.CHARTERED },
    { key: 1, label: TEXTS.SHARED_LABEL, value: FLIGHT_TYPES.SHARED },
  ];
  // REFS
  const calendarRef = createRef();
  const timerBubbleRef = createRef();
  const bubbleParentRef = createRef();
  const lpFormRef = createRef();

  const [locationsOriginList, setLocationsOriginList] = useState([]);
  const [locationsDestinationList, setLocationsDestinationList] = useState([]);
  const [today, setToday] = useState(new Date());
  const [destinationPlace, setDestinationPlace] = useState(
    initialDestinationSuggestion ? initialDestinationSuggestion : ''
  );
  const [originPlace, setOriginPlace] = useState(
    initialOriginSuggestion ? initialOriginSuggestion : ''
  );
  const [swapPlaces, setSwapPlaces] = useState(false);
  const [adults, setAdults] = useState(1);
  const [kids, setKids] = useState(0);
  const [dateRangeState, setDateRangeState] = useState([
    {
      startDate: today,
      endDate: today,
      key: 'selection',
    },
  ]);
  const [departureDate, setDepartureDate] = useState(today);
  const [returnDate, setReturnDate] = useState(null);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [timePickerOptions, setTimePickerOptions] = useState({
    isVisible: false,
    type: null,
    departureTime: dateToHour(today),
    returnTime: null,
  });
  const [errorMsg, setErrorMsg] = useState('');
  const [bubbleState, setBubbleState] = useState({
    isVisible: false,
    type: null,
  });
  const [currentFlightType, setCurrentFlightType] = useState(
    flightTypesOptions[0]
  );

  const currentLang = useCurrentLang();
  const intlLang = currentLang.replace('_', '-');
  const allAirportsLabel = TEXTS.ALL_AIRPORTS;
  const [flightTypeFormActive, setFlightTypeFormActive] = useState(false);

  useEffect(() => {
    setToday(initialDay ? new Date(initialDay) : new Date());
    setDateRangeState([
      {
        startDate: initialDay ? new Date(initialDay) : new Date(),
        endDate: initialDay ? new Date(initialDay) : new Date(),
        key: 'selection',
      },
    ]);
    setDepartureDate(today);
  }, []);

  useEffect(() => {
    async function fetchLocations() {
      const url = apiHost + getLocationsRoute + '?name=' + originPlace;
      /* const options = {
        method: 'GET',
      }; */

      try {
        //const response = await fetch(url, options);
        debounce(async () => {
          const { data } = await useRequest({
            url: url,
            method: 'get',
            useSession: false,
          });
          //const data = await response.json();

          if (data.result.status === 0) {
            const locations = data.locations.map(location =>
              locationToSuggestion(location)
            );
            setLocationsOriginList(locations);
          }
        }, 500);
      } catch (e) {
        console.log(e);
      }
    }
    fetchLocations();
  }, [originPlace]);

  useEffect(() => {
    async function fetchLocations() {
      const url = apiHost + getLocationsRoute + '?name=' + destinationPlace;
      /* const options = {
        method: 'GET',
      }; */

      try {
        //const response = await fetch(url, options);
        debounce(async () => {
          const { data } = await useRequest({
            url: url,
            method: 'get',
            useSession: false,
          });
          //const data = await response.json();

          if (data.result.status === 0) {
            const locations = data.locations.map(location =>
              locationToSuggestion(location)
            );
            setLocationsDestinationList(locations);
          }
        }, 500);
      } catch (e) {
        console.log(e);
      }
    }
    fetchLocations();
  }, [destinationPlace]);

  const locationToSuggestion = ({ type, city, spotId, spot }) => {
    let suggestion;
    if (type === 'City') {
      suggestion = {
        country: 'Brasil',
        city: city.name,
        type: 'city',
        id: city.id,
        location: allAirportsLabel,
        code: city.iata ? city.iata : '',
        name: city.name,
      };
    } else if (type === 'Spot') {
      suggestion = {
        country: 'Brasil',
        city: city.name,
        type: 'spot',
        id: spotId,
        location: spot.name,
        code: solveIataOaci(spot, 'only-once'),
        name: spot.name,
        initialOriginId,
      };
    }
    if (suggestion.code == null) suggestion.code = '';
    return suggestion;
  };

  const setDateRange = selection => {
    setDateRangeState(selection);
    const { startDate, endDate } = selection[0];
    const now = new Date();
    let type = null;

    if (startDate === endDate) {
      setDepartureDate(startDate);
      setReturnDate(null);
      type = 'start';
    } else {
      setDepartureDate(startDate);
      setReturnDate(endDate);
      type = 'end';
    }

    setTimePickerOptions({
      isVisible: true,
      type,
    });
  };

  const setDepartureTimes = data => {
    setTimePickerOptions({
      isVisible: false,
      type: null,
      departureTime: data.departureTime,
      returnTime: data.returnTime,
    });

    setBubbleState({
      isVisible: false,
      type: null,
    });
  };

  const compareToEdit = date => {
    let positionTop = 0;
    let positionLeft = 0;
    let isVisible = false;
    let bubbleType = null;
    const calendar = findDOMNode(calendarRef.current);
    const bubbleComponent = timerBubbleRef.current;
    const bubbleNode = findDOMNode(bubbleComponent);
    const bubbleParent = findDOMNode(bubbleParentRef.current);
    const [parentTop, parentLeft] = getNodePosition(bubbleParent);
    let [offsetTop, offsetLeft] = [120, 155];

    if (date && departureDate && returnDate) {
      const departureRef = {
        month: departureDate.getMonth() + 1,
        day: departureDate.getDate(),
      };

      const returnRef = {
        month: returnDate.getMonth() + 1,
        day: returnDate.getDate(),
      };

      const currentDate = {
        month: date.getMonth() + 1,
        day: date.getDate(),
      };

      const nodes = Array.from(
        calendar.getElementsByClassName('rdrDayActive')
      ).filter(el => {
        try {
          const day = parseInt(el.childNodes[1].childNodes[0].innerText, 0);
          return day === departureRef.day || day === returnRef.day;
        } catch (err) {
          return false;
        }
      });

      const compareDeparture =
        departureRef.month === currentDate.month &&
        departureRef.day === currentDate.day;
      const compareReturn =
        returnRef.month === currentDate.month &&
        returnRef.day === currentDate.day;

      if (compareDeparture && compareReturn) {
        [positionTop, positionLeft] = getNodePosition(nodes[0]);
        [offsetTop, offsetLeft] = [127, 222];
        isVisible = true;
        bubbleType = 'both';
      } else if (compareDeparture) {
        [positionTop, positionLeft] = getNodePosition(nodes[0]);
        isVisible = true;
        bubbleType = 'start';
      } else if (compareReturn) {
        [positionTop, positionLeft] = getNodePosition(nodes[nodes.length - 1]);
        isVisible = true;
        bubbleType = 'end';
      } else {
        isVisible = false;
        bubbleType = null;
      }

      setBubbleState({
        isVisible,
        type: bubbleType,
      });

      bubbleNode.style.top = `${parentTop + positionTop - offsetTop}px`;
      bubbleNode.style.left = `${parentLeft + positionLeft - offsetLeft}px`;
    }
  };

  const closeBubble = () => {
    setBubbleState(oldState => ({ ...oldState, isVisible: false }));
  };

  const onBubbleEdit = type => {
    setTimePickerOptions({
      isVisible: true,
      type,
    });

    closeBubble();
  };

  const handleSelectFlightType = type => {
    setCurrentFlightType(type);
    handleShowFlightTypeForm(false);
  };

  const handleShowFlightTypeForm = active => {
    setFlightTypeFormActive(active);
  };

  const handleSelectSuggestion = (data, isOrigin) => {
    if (isOrigin) setOriginPlace(data);
    else setDestinationPlace(data);
  };

  const handleSwapPlaces = () => {
    setSwapPlaces(!swapPlaces);
  };

  const handleDateSelection = item => {
    setDateRange([item.selection]);
  };

  const handleDatetimeSelection = datetime => {
    setDepartureTimes(datetime);
  };

  useOutsideClick(lpFormRef, () => {
    setOpenDatePicker(false);
  });

  const handleSubmit = setErrorMsg => event => {
    event.preventDefault();
    const data = new FormData(event.target);

    const formValues = {};
    formValues.originSpotId = data.get('originSpotId');
    formValues.destinationSpotId = data.get('destinationSpotId');
    formValues.departureDate = stringToDate(data.get('departure'));
    formValues.returnDate = stringToDate(data.get('return'));
    formValues.departureTime = data.get('departureTime');
    formValues.returnTime = data.get('returnTime');
    formValues.adults = data.get('adults');
    formValues.kids = data.get('kids');
    formValues.flightType = currentFlightType.value;

    if (formValues.originSpotId === '') {
      setErrorMsg('Selecione uma origem da lista de opções');
      return;
    }
    if (formValues.destinationSpotId === '') {
      setErrorMsg('Selecione um destino da lista de opções');
      return;
    }
    if (formValues.departureDate === '') {
      setErrorMsg('Selecione uma data de partida');
      return;
    }
    if (parseInt(formValues.adults) < 1) {
      setErrorMsg('Selecione ao menos um passageiro adulto');
      return;
    }

    setErrorMsg('');

    const searchLink =
      '/p/search/' +
      Object.values(formValues)
        .map(formValue => (formValue ? encodeURIComponent(formValue) : '-'))
        .join('/');

    analyticsGTAG('click', {
      event_category: 'Quotation',
      event_action: 'Search',
      event_label: 'User make a quotation',
    });

    gtmDispatcher('search', {
      event_category: 'form_search',
      event_action: 'submit',
      event_label: currentFlightType.value,
      value: {
        departure: {
          date: formValues.departureDate,
          time: formValues.departureTime,
          place: formValues.destinationSpotId.split('&')[2],
        },
        arrival: {
          returnDate: formValues.returnDate,
          returnTime: formValues.returnTime,
          place: formValues.originSpotId.split('&')[2],
        },
      },
    });

    navigate(`${searchLink}`);
  };

  return (
    <div className={Style.formContainer}>
      <div
        className={[
          Style.flightTypeFormBlur,
          flightTypeFormActive ? Style.opened : '',
        ].join(' ')}
      ></div>
      <div
        className={[
          Style.flightTypeForm,
          flightTypeFormActive ? Style.opened : '',
        ].join(' ')}
      >
        <div
          className={[
            Style.flightTypeLabelContainer,
            currentFlightType.value === FLIGHT_TYPES.CHARTERED
              ? Style.active
              : '',
          ].join(' ')}
          onClick={() => handleSelectFlightType(flightTypesOptions[0])}
        >
          <span className={Style.flightTypeLabel} data-translation="tm:charter">
            {TEXTS.CHARTER_LABEL}
          </span>
        </div>
        <div
          className={[
            Style.flightTypeLabelContainer,
            currentFlightType.value === FLIGHT_TYPES.SHARED ? Style.active : '',
          ].join(' ')}
          onClick={() => handleSelectFlightType(flightTypesOptions[1])}
        >
          <span className={Style.flightTypeLabel} data-translation="tm:shared">
            {TEXTS.SHARED_LABEL}
          </span>
        </div>
      </div>
      <form
        ref={lpFormRef}
        onSubmit={handleSubmit(setErrorMsg)}
        className={[Style.lpForm, errorMsg !== '' ? Style.showError : ''].join(
          ' '
        )}
      >
        <div
          className={[Style.formPlaces, swapPlaces ? Style.swaped : ''].join(
            ' '
          )}
        >
          <div
            className={[Style.selectContainer, Style.originContainer].join(' ')}
          >
            <LocationsAutoSuggest
              readOnly={onlyDestination}
              initialCityId={initialOriginId}
              onChangeSuggest={setOriginPlace}
              onSelectSuggestion={data =>
                handleSelectSuggestion(data, !swapPlaces)
              }
              name={swapPlaces ? 'destination' : 'origin'}
              className={Style.originSuggest}
              locationsList={locationsOriginList}
              placeholder={swapPlaces ? TEXTS.DESTINATION : TEXTS.ORIGIN}
              dataTranslation={swapPlaces ? 'destination' : 'origin'}
            />
          </div>
          <i
            onClick={onlyDestination ? null : () => handleSwapPlaces()}
            className={[Style.togglePlaces, 'togglePlaces'].join(' ')}
            dangerouslySetInnerHTML={{
              __html: onlyDestination ? onlyDestinationicon : swapIcon,
            }}
          />
          <div
            className={[Style.selectContainer, Style.destinationContainer].join(
              ' '
            )}
          >
            <LocationsAutoSuggest
              onChangeSuggest={setDestinationPlace}
              onSelectSuggestion={data =>
                handleSelectSuggestion(data, swapPlaces)
              }
              onLeave={() => handleShowFlightTypeForm(true)}
              initialCityId={initialDestinationId}
              name={swapPlaces ? 'origin' : 'destination'}
              className={Style.destinationSuggest}
              locationsList={locationsDestinationList}
              placeholder={swapPlaces ? TEXTS.ORIGIN : TEXTS.DESTINATION}
              dataTranslation={swapPlaces ? 'origin' : 'destination'}
            />
          </div>
        </div>
        <div className={Style.flightTypeSelectWrapper}>
          <DropDown
            list={flightTypesOptions}
            selectedIndex={currentFlightType.key}
            onSelectItem={handleSelectFlightType}
          ></DropDown>
        </div>
        <LpFormTimePicker
          onSave={handleDatetimeSelection}
          options={timePickerOptions}
        />
        <div
          className={Style.timerBubbleContainer}
          ref={timerBubbleRef}
          onMouseLeave={() => closeBubble()}
        >
          {bubbleState.isVisible && !timePickerOptions.isVisible ? (
            <LpFormTimePickerEditBubble
              departureTime={timePickerOptions.departureTime}
              returnTime={timePickerOptions.returnTime}
              type={bubbleState.type}
              onEdit={type => onBubbleEdit(type)}
            />
          ) : null}
        </div>
        <div
          ref={bubbleParentRef}
          className={[
            Style.hiddenContainer,
            'hiddenContainer',
            openDatePicker ? Style.opened : '',
          ].join(' ')}
        >
          <div
            className={Style.hiddenContainerBackground}
            style={{
              background: backgroundImage
                ? 'url(' + backgroundImage + ') 0 / cover scroll'
                : '#747e7e',
            }}
          ></div>
          <div className={Style.formDates}>
            <div
              onClick={() => setOpenDatePicker(!openDatePicker)}
              className={[
                Style.dateIndicator,
                departureDate ? Style.departureSelected : '',
                returnDate ? Style.returnSelected : '',
              ].join(' ')}
            >
              <input
                readOnly
                name="departure"
                type="text"
                hidden
                value={departureDate ? dateToString(departureDate) : ''}
              />
              <input
                readOnly
                name="return"
                type="text"
                hidden
                value={returnDate ? dateToString(returnDate) : ''}
              />
              <input
                readOnly
                name="departureTime"
                type="text"
                hidden
                value={
                  timePickerOptions.departureTime
                    ? timePickerOptions.departureTime
                    : ''
                }
              />
              <input
                readOnly
                name="returnTime"
                type="text"
                hidden
                value={
                  timePickerOptions.returnTime
                    ? timePickerOptions.returnTime
                    : ''
                }
              />
              {departureDate && (
                <span className={Style.departureMonth}>
                  {dateToMonthName(departureDate, intlLang)}
                </span>
              )}
              <span className={Style.departureDay}>
                {departureDate ? dateToDay(departureDate) : '+'}
              </span>
              <i
                className={Style.rightArrow}
                dangerouslySetInnerHTML={{ __html: rightArrowIcon }}
              />
              <span className={Style.returnDay}>
                {returnDate ? dateToDay(returnDate) : '+'}
              </span>
              {returnDate &&
                dateToMonthName(returnDate, intlLang) !=
                  dateToMonthName(departureDate, intlLang) && (
                  <span className={Style.returnMonth}>
                    {dateToMonthName(returnDate, intlLang)}
                  </span>
                )}
            </div>
            <div ref={calendarRef} className={Style.datePicker}>
              <DateRange
                ranges={dateRangeState}
                locale={localeLangsMap[currentLang]}
                onChange={item => handleDateSelection(item)}
                onPreviewChange={date => compareToEdit(date)}
                minDate={today}
                {...datePickerSettings}
              />
            </div>
          </div>
          <div className={[Style.formPeople, 'formPeople'].join(' ')}>
            <div className={Style.peopleLabel}>{TEXTS.PLACES}</div>
            <div className={Style.adults}>
              <span>{TEXTS.ADULTS_ONLY}</span>
              <span>16+</span>
            </div>
            <NumberInput
              name="adults"
              min={1}
              max={19}
              setNumber={setAdults}
              value={adults}
            />
            <div className={Style.kids}>
              <span>{TEXTS.CHILDRENS}</span>
              <span>2 - 15</span>
            </div>
            <NumberInput
              name="kids"
              min={0}
              max={19}
              setNumber={setKids}
              value={kids}
            />
          </div>
        </div>
        <label className={Style.submitContainer}>
          <button className={Style.formSubmit} type="submit" />
          <i
            className={Style.submitIcon}
            dangerouslySetInnerHTML={{ __html: submitIcon }}
          />
        </label>
        <p className={Style.errorMsg}>{errorMsg}</p>
      </form>
    </div>
  );
};

export default LpForm;
