// TODO: REFACTORING
import React from 'react';
import { translate as t } from '../../utils/translate';
import Style from './style.module.css';

const LpFormTimePickerEditBubble = ({
  type,
  departureTime,
  returnTime,
  onEdit,
}) => {
  const TEXTS = {
    GOING: t('going'),
    RETURNING: t('returning'),
    EDIT_BUTTON: t('editButton'),
  };

  return (
    <div className={Style.mainContainer}>
      {type !== 'both' ? (
        <>
          <div className={Style.timeContainer}>
            <span className={Style.label}>
              {type === 'start' ? TEXTS.GOING : TEXTS.RETURNING}
            </span>
            <span className={Style.time}>
              {type === 'start' ? departureTime : returnTime}
            </span>
          </div>
          <button onClick={() => onEdit(type)} className={Style.button}>
            {TEXTS.EDIT_BUTTON}
          </button>
        </>
      ) : (
        <>
          <div className={Style.timeContainer}>
            <span className={Style.label}>{TEXTS.GOING}</span>
            <span className={Style.time}>{departureTime}</span>
            <button
              onClick={() => onEdit('start')}
              className={`${Style.buttonInline} inline`}
            >
              {TEXTS.EDIT_BUTTON}
            </button>
          </div>
          <div className={Style.timeContainer}>
            <span className={Style.label}>{TEXTS.RETURNING}</span>
            <span className={Style.time}>{returnTime}</span>
            <button
              onClick={() => onEdit('end')}
              className={Style.buttonInline}
            >
              {TEXTS.EDIT_BUTTON}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default LpFormTimePickerEditBubble;
