/**
 * Must use require ok!! it's for the sake o gatsby build/develop
 */
let PATH_DICT = require('./langPath').PATH_DICT;
let aircraftNameHelper = require('./aircraftNameHelper');

/**
 *
 * Generate aircraft to be used on backoffice
 *
 * @param {object} aircraft
 * @param {string} locale
 * @returns
 */
const makeAircraftLink = (aircraft, locale = 'pt_BR') => {
  let link = `${PATH_DICT[locale].path}aircraft/${aircraft.id}`;

  if (
    aircraft.model &&
    aircraft.model.type != null &&
    aircraft.model.type != ''
  ) {
    let model = aircraft.model;
    let manufacturer =
      (model.producer && model.producer.name) || model.manufacturer;
    let type = model.type;
    let modelName = model.model;

    if (modelName != manufacturer) {
      link += `/${manufacturer.toLowerCase()}`;
    }
    link += `/${modelName.toLowerCase()}/${type.toLowerCase()}`;
  } else {
    const aircraftModelName = aircraftNameHelper(aircraft).trim();

    link += `/${aircraftModelName.toLowerCase()}`;
  }

  return link.replace(/ /g, '-');
};

module.exports = makeAircraftLink;
exports = makeAircraftLink;
