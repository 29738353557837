// TODO: REFACTORING
import React, { useState, useEffect, Fragment, useRef } from 'react';
import Style from './style.module.css';
import Autosuggest from 'react-autosuggest';

const LocationsAutoSuggest = ({
  className,
  placeholder,
  onChangeSuggest,
  onSelectSuggestion,
  onLeave,
  locationsList,
  initialCityId,
  name,
  readOnly,
  dataTranslation,
}) => {
  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [spotId, setSpotId] = useState('');
  const [initialValue, setInitialValue] = useState(false);
  const [locations, setLocations] = useState([]);
  const [hasLoaded, setHasLoaded] = useState(false);

  const initialCity = locations.filter(
    location => initialCityId && location.id === initialCityId
  );

  if (!initialValue && initialCity.length === 1) {
    setValue(initialCity[0].city + ', ' + initialCity[0].code);
    setSpotId(
      [
        initialCity[0].type[0],
        initialCityId,
        initialCity[0].name,
        initialCity[0].code,
      ].join('&')
    );
    setInitialValue(true);
  }

  const getSuggestions = suggestionValue => {
    return locationsList;
    // const inputValue = suggestionValue.trim().toLowerCase();
    // const inputLength = inputValue.length;
    //   return inputLength === 0
    //     ? locations
    //     : locations.filter(
    //         suggestion =>
    //           suggestion.city.toLowerCase().includes(inputValue) ||
    //           suggestion.code.toLowerCase().includes(inputValue) ||
    //           suggestion.location.toLowerCase().includes(inputValue)
    //       );
  };

  useEffect(() => {
    if (!hasLoaded) {
      setTimeout(() => {
        setHasLoaded(true);
      }, 1500);
    }

    setLocations(locationsList);
    onSuggestionsFetchRequested({ value });
  }, [locationsList]);

  const getSuggestionValue = suggestion => {
    return suggestion.city + ', ' + suggestion.code;
  };

  const renderSuggestion = suggestion => {
    return (
      <div className={Style.suggestion}>
        <div className={Style.codeContainer}>
          <span className={Style.code}>
            {suggestion.code ? suggestion.code : 'ZZZZ'}
          </span>
        </div>
        <div className={Style.locationWrapper}>
          <div className={Style.city}>
            {suggestion.type === 'spot' ? suggestion.location : suggestion.city}
          </div>
          <div className={Style.location}>
            <span>
              {suggestion.type === 'spot'
                ? suggestion.city
                : suggestion.location}
            </span>
          </div>
        </div>
      </div>
    );
  };
  const onSuggestionSelected = (event, { suggestion }) => {
    setSpotId(
      [
        suggestion.type[0],
        suggestion.id,
        suggestion.name,
        suggestion.code,
      ].join('&')
    );
    onSuggestionsClearRequested();
    onSelectSuggestion(getSuggestionValue(suggestion));
    //onChangeSuggest(getSuggestionValue(suggestion));
    if (onLeave) onLeave();
  };

  const onChange = (event, { newValue }) => {
    setSpotId('');
    setValue(newValue);
    onChangeSuggest(newValue);
  };

  const onFocus = () => {
    setSpotId('');
    setValue('');
    onSuggestionsFetchRequested({ value });
  };

  function shouldRenderSuggestions(value) {
    return true;
  }

  const onSuggestionsFetchRequested = ({ value }) =>
    setSuggestions(getSuggestions(value));

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const inputProps = {
    placeholder: placeholder,
    value,
    onChange: onChange,
    onFocus: onFocus,
    className: className ? className : '',
    name: name,
    readOnly: readOnly,
    'data-translation': `tm:${dataTranslation}`,
  };

  return !hasLoaded ? (
    <div className={Style.inputPlaceholder}></div>
  ) : (
    <Fragment>
      <input name={name + 'SpotId'} type="hidden" value={spotId} />
      <Autosuggest
        renderSectionTitle={() => false}
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        focusInputOnSuggestionClick={false}
        shouldRenderSuggestions={shouldRenderSuggestions}
      />
    </Fragment>
  );
};

export default LocationsAutoSuggest;
