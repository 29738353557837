const PT_BR = 'pt_BR';
const ES_ES = 'es_ES';
const EN_US = 'en_US';
// const IT_IT = 'it_IT';

const PT_BR_PATH = { path: '/', polylang_current_lang: PT_BR };
const ES_ES_PATH = { path: '/es/', polylang_current_lang: ES_ES };
const EN_US_PATH = { path: '/en/', polylang_current_lang: EN_US };
// const IT_IT_PATH = { path: '/it/', polylang_current_lang: IT_IT };

module.exports.PATH_DICT = {
  [PT_BR]: PT_BR_PATH,
  [ES_ES]: ES_ES_PATH,
  [EN_US]: EN_US_PATH,
  // [IT_IT]: IT_IT_PATH,
};

module.exports.default = [PT_BR_PATH, ES_ES_PATH, EN_US_PATH];
