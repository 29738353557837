import { apiHost } from '@utils/constants';
import { dateToHour, dateToRequestFormat } from '@utils/dates';
import { formatCurrency } from '@utils/currency';

import { translate as t } from '@utils/translate';
import makeAircraftLink from '@utils/makeAircraftLink';
import { getCurrentLang } from '@utils/language';

/**
 * Returns the passed object (routeParams) decoded
 *
 * @param {object} routeParams
 */
const decodeParams = params => {
  // Decoding params
  Object.entries(params).forEach(([key, value]) => {
    params[key] = decodeURIComponent(value);
  });
};

/**
 * Returns an reduced object from params
 *
 * @param {object} routeParams
 */
const reduceLocationParams = params => {
  return { type: params[0], id: params[1], name: params[2], code: params[3] };
};

const prepareQueryString = additionalQueryString => {
  let qs = '';
  if (typeof window !== 'undefined') {
    let currentParams = new URLSearchParams(window.location.search);
    let urlParams = new URLSearchParams('?' + additionalQueryString);

    if (urlParams.toString()) qs += '?' + urlParams.toString();
    if (currentParams.toString())
      qs += qs
        ? '&' + currentParams.toString()
        : '?' + currentParams.toString();
  }
  return qs;
};

/**
 * Returns a mapped array of aircraft models
 *
 * @param {boolean} isShared Is shared flight?
 * @param {object} data Result data
 */
const mapResultAircraftModels = (isShared, route) => {
  const aircraftModels = isShared
    ? [
        {
          aircraftModel: route['aircraft']['model'],
          startingPrice: parseFloat(route['price']),
          aircrafts: [
            {
              aircraft: { ...route['aircraft'] },
              price: parseFloat(route['price']),
              currency: route['currency'],
              estimatedTime: route['estimatedTime'],
            },
          ],
        },
      ]
    : route['aircraftModels'];

  return aircraftModels;
};

/**
 * Returns a mapped array of routes
 *
 * @param {boolean} isShared Is shared flight?
 * @param {object} data Result data
 */
const mapResultRoutes = (isShared, data) => {
  const routes = isShared ? data['flights'] : data['quotation']['routes'];

  const routesList = routes.map((route, index) => {
    const obj = {
      ...route,
      mappedIndex: index,
      aircraftModels: mapResultAircraftModels(isShared, route),
    };
    return obj;
  });
  return routesList;
};

const getLangByISOCurrency = {
  USD: 'en-US',
  BRL: 'pt-BR',
  EUR: 'pt-PT',
};

/**
 * @param {number} value | 10000,00
 * @param {number} currencyCode - USD | BRL | EUR ...
 */
const getFormatedCurrencyByCode = (value, currencyCode) => {
  return Number(value).toLocaleString(
    getLangByISOCurrency[currencyCode] || 'pt-BR',
    {
      style: 'currency',
      currency: currencyCode,
      minimumFractionDigits: 0,
      currencyDisplay: 'code',
    }
  );
};

/**
 * Returns a mapped array of aircrafts
 *
 * @param {object} data Result data
 */
// const mapResultAircraftSales = (data, type, currency, propName) => {
const mapResultAircraftSales = (data, type, currency, propName) => {
  const lang = getCurrentLang();
  const aircraftGroups = data[type][0];
  const mappedAircraftsData = {};
  //const groupPropName = propName === 'producers' ? 'producerName' : 'sizeName';
  const TEXTS = {
    PRICE_LABEL: t('startingAt'),
  };

  try {
    const sortedGroups = Object.keys(aircraftGroups).reduce((obj, key) => {
      obj[key] = aircraftGroups[key];
      return obj;
    }, {});

    for (const group in sortedGroups) {
      if (Object.hasOwnProperty.call(sortedGroups, group)) {
        const aircraftGroup = sortedGroups[group];

        const aircrafts = aircraftGroup.aircrafts.map((aircraft, index) => {
          const aircraftPageUrl = makeAircraftLink(aircraft, lang);
          return {
            name: aircraft.name,
            paintingYear: aircraft.paintingYear,
            model: aircraft.model,
            imageUrl: aircraft.model.highlightImageUrl,
            title: aircraftGroup['groupName'],
            subtitle: aircraft.name,
            unformattedPrice: parseInt(aircraft.priceToSell),
            priceLabel: TEXTS.PRICE_LABEL,
            autonomy: parseInt(aircraft.model.maxRange),
            seats: aircraft.quantityOfPassengers,
            // price: `${Number(aircraft.priceToSell).toLocaleString(
            //   getLangByISOCurrency[aircraft.currencyToSell] || 'pt-BR',
            //   {
            //     style: 'currency',
            //     currency: aircraft.currencyToSell,
            //     minimumFractionDigits: 0,
            //     currencyDisplay: 'code',
            //   }
            // )}`,
            price: getFormatedCurrencyByCode(
              aircraft.priceToSell,
              aircraft.currencyToSell
            ),
            link: `${aircraftPageUrl}?forSale=1`,
          };
        });

        if (!mappedAircraftsData[aircraftGroup['groupName']])
          mappedAircraftsData[aircraftGroup['groupName']] = {};
        mappedAircraftsData[aircraftGroup['groupName']][
          'aircrafts'
        ] = aircrafts;
        // if (!mappedAircraftsData[aircraftSize.producerName]) {
        //   mappedAircraftsData[aircraftSize.producerName] = {};
        // }
        // mappedAircraftsData[aircraftSize.producerName]['aircrafts'] = aircrafts;
      }
    }
  } catch (error) {
    console.log(error);
  }
  return mappedAircraftsData;
};

/**
 * Returns api url based on flight type
 *
 * @param {string} flightType String as ('charter', 'flights', 'empty-legs')
 */
const getFlightTypeRoute = flightType => {
  let endpoint = '';

  switch (flightType) {
    case 'charter':
      endpoint = `${apiHost}/api/v1/flight/quote`;
      break;
    case 'flights':
      endpoint = `${apiHost}/api/v1/flight/getavailableflights`;
      break;
    case 'empty-legs':
      endpoint = `${apiHost}`;
      break;

    default:
      endpoint = `${apiHost}/api/v1/flight/quote`;
      break;
  }

  return endpoint;
};

/**
 * Returns DATA prepared for search results
 *
 * @param {object} data Response data
 */
const formatResponseForSearchResults = data => {
  if (!data) return null;

  try {
    const obj = {
      routes: null,
      isShared: data['flights'] ? true : false,
    };

    obj.routes = mapResultRoutes(obj.isShared, data);

    return obj;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * Returns DATA prepared for search results listing aircrafts for sale
 *
 * @param {object} data Response data
 */
const formatResponseForSearchSalesResults = (
  data,
  currency = 'BRL',
  selectedGroupBy = 'producers'
) => {
  if (!data) return null;
  const TEXTS = {
    AIRPLANES: t('airplanes'),
    HELICOPTERS: t('helicopters'),
  };

  try {
    const obj = {
      aircrafts: [
        {
          groupId: 1,
          title: TEXTS.AIRPLANES,
          subtitle: '',
          items: mapResultAircraftSales(
            data,
            'airplanes',
            currency,
            selectedGroupBy
          ),
        },

        {
          groupId: 2,
          title: TEXTS.HELICOPTERS,
          subtitle: '',
          items: mapResultAircraftSales(
            data,
            'helicopters',
            currency,
            selectedGroupBy
          ),
        },
      ],
    };

    return obj;
  } catch (error) {
    console.error(error);
    return null;
  }
};

/**
 * Returns flight type id
 *
 * @param {string} flightType String as ('charter', 'flights', 'empty-legs')
 */
const getFlightTypeId = flightType => {
  let id = null;

  switch (flightType) {
    case 'charter':
      id = 2;
      break;
    case 'flights':
      id = 4;
      break;
    case 'empty-legs':
      id = null;
      break;

    default:
      id = 2;
      break;
  }

  return id;
};

/**
 * Returns flight type string based on its id
 *
 * @param {string} id ID as String
 */
const getFlightTypeString = id => {
  let type = '';

  switch (id) {
    case 2:
      type = 'charter';
      break;
    case 4:
      type = 'flights';
      break;
    /* case X:
      type = 'empty-legs';
      break; */

    default:
      type = 'charter';
      break;
  }

  return type;
};

/**
 * Returns flight search url
 *
 * @param {object} flight Flight data
 * @param {string} flightType Flight type (charter, flights, empty-legs)
 */
const getFlightSearchUrl = (
  flight,
  flightType = 'charter',
  additionalQueryString = ''
) => {
  let url = '';
  let date = null;
  const origin = {
    type: flight.originCity ? 'city' : 'spot',
    id: flight.originCity ? flight.originCity.id : flight.originSpot.id,
    name: flight.originCity ? flight.originCity.name : flight.originSpot.name,
    iata: flight.originCity ? flight.originCity.iata : flight.originSpot.iata,
  };
  const destination = {
    type: flight.destinationCity ? 'city' : 'spot',
    id: flight.destinationCity
      ? flight.destinationCity.id
      : flight.destinationSpot.id,
    name: flight.destinationCity
      ? flight.destinationCity.name
      : flight.destinationSpot.name,
    iata: flight.destinationCity
      ? flight.destinationCity.iata
      : flight.destinationSpot.iata,
  };

  if (flight.nextFlight && flight.nextFlight.flightDatetime) {
    // TODO: Refactor this
    date = new Date(flight.nextFlight.flightDatetime.replace(' ', 'T'));
  } else {
    date = new Date();
    date.setHours(date.getHours() + 3);
  }

  const departureDate = flight.departureDate
    ? flight.departureDate
    : encodeURIComponent(dateToRequestFormat(date));
  const departureTime = flight.departureTime
    ? flight.departureTime
    : encodeURIComponent(dateToHour(date));

  const returnDate = flight.returnDate ? flight.returnDate : '-';
  const returnTime = flight.returnTime ? flight.returnTime : '-';

  let originCity = `${origin.id}&${origin.name}&${origin.iata}`;
  let destinationCity = `${destination.id}&${destination.name}&${destination.iata}`;

  originCity = encodeURIComponent(
    `${origin.type === 'spot' ? 's' : 'c'}&${originCity}`
  );
  destinationCity = encodeURIComponent(
    `${destination.type === 'spot' ? 's' : 'c'}&${destinationCity}`
  );

  url = `/p/search/${originCity}/${destinationCity}/${departureDate}/${returnDate}/${departureTime}/${returnTime}/1/0/${flightType}`;

  url += prepareQueryString(additionalQueryString);

  return url;
};

/**
 * Returns flight detail url
 *
 * @param {object} flight Flight data
 */
const getFlightDetailUrl = (flight, additionalQueryString = '') => {
  let url = '';
  const flightId = flight.nextFlight ? flight.nextFlight.id : flight.id;
  const aircraftId = flight.nextFlight
    ? flight.nextFlight.aircraftId
    : flight.aircraftId;

  url = `/p/flight/${flightId}/${aircraftId}`;

  url += prepareQueryString(additionalQueryString);

  return url;
};

/**
 * Returns flights with minimum available seats
 *
 * @param {array} flights Array of objects (Flights)
 * @param {number} seats Number of available seats
 */
const filterFlightsByNumberOfSeats = (flights, seats) => {
  let maxSeats = 0;
  const filteredFlights = flights.filter(flight => {
    if (flight.availableSeats > maxSeats) maxSeats = flight.availableSeats;
    return flight.availableSeats >= seats;
  });
  return [filteredFlights, maxSeats];
};

export {
  getFlightTypeRoute,
  formatResponseForSearchResults,
  formatResponseForSearchSalesResults,
  getFlightTypeId,
  getFlightTypeString,
  getFlightSearchUrl,
  getFlightDetailUrl,
  filterFlightsByNumberOfSeats,
  decodeParams,
  reduceLocationParams,
  getFormatedCurrencyByCode,
};
