let aircraftModelNameHelper = require('./aircraftModelNameHelper');

const aircraftNameHelper = aircraft => {
  if (aircraft.name != null && aircraft.name.length > 0) {
    return aircraft.name;
  } else {
    return aircraftModelNameHelper(aircraft.model);
  }
};

module.exports = aircraftNameHelper;
