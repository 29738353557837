import { capitalize } from './string.js';

export const dateToString = (date, lang = 'pt-BR') =>
  new Intl.DateTimeFormat(lang).format(date);

export const stringToDate = date => {
  const strDate = typeof date === 'object' ? dateToString(date) : date;
  return strDate
    .split('/')
    .reverse()
    .join('-');
};

export const formatDateString = (date, time = '12:00') => {
  if (!date) return '';

  const rgx = /^([0-1][0-9]|[2][0-3]):([0-5][0-9])$/;

  time = time.match(rgx) ? time : '12:00';
  return `${date
    .split('/')
    .reverse()
    .join('-')}T${time}`;
};

export const dateToMonthName = (date, lang = 'pt-BR') => {
  const options = {
    month: 'long',
  };

  return new Intl.DateTimeFormat(lang, options).format(date);
};

export const dateToDay = (date, lang = 'pt-BR') => {
  const options = {
    day: '2-digit',
  };

  return new Intl.DateTimeFormat(lang, options).format(date);
};

export const dateToCardFormat = (strDate, language = 'pt-BR') => {
  const options = {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  };
  const formatedDate = strDate.replaceAll('-', '/');
  return new Intl.DateTimeFormat(language, options).format(
    new Date(formatedDate)
  );
};

export const dateToRequestFormat = (strDate, language = 'pt-BR') => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat(language, options).format(new Date(strDate));
};

export const dateToHour = (date, language = 'pt-BR') => {
  const options = {
    hour: '2-digit',
    minute: '2-digit',
  };

  return new Intl.DateTimeFormat(language, options).format(
    typeof date === 'string' ? new Date(date.replaceAll('-', '/')) : date
  );
};

export const dateToFlightDetailsFormat = (strDate, language = 'pt-BR') => {
  const options = {
    year: 'numeric',
    month: 'long',
    weekday: 'long',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  };
  const formatedStrDate = strDate.replaceAll('-', '/');
  const date = new Intl.DateTimeFormat(language, options);

  const formatedDate = date
    .formatToParts(new Date(formatedStrDate))
    .map(({ type, value }) => {
      switch (type) {
        case 'weekday':
          return capitalize(value);

        case 'year':
          return language == 'pt-BR' ? `${value} às` : value;
        default:
          return value;
      }
    })
    .reduce((string, part) => string + part, '');

  return formatedDate;
};

export const dateToYear = date => {
  let datetime = date;
  const regex = new RegExp('^(d{4})-(d{2})-(d{2}) (d{2}):(d{2}):(d{2})$');

  if (!regex.test(date)) {
    datetime = `${date} 00:00:00`;
  }

  return new Date(datetime).getFullYear();
};

export const minutesToHours = minutes =>
  `${(minutes / 60) ^ 0}h${minutes % 60}m`;
